import { createI18n } from 'vue-i18n';
import { ProfeatUI } from './';

import './src/assets/styles/global/index.scss';
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/800.css';

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru'
});

export function setupVue3({ app }) {
  app.use(i18n);
  app.use(ProfeatUI);
}
